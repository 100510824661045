$(document).ready(function () {
  // Accordion
  $(".text-accordion__content").hide();

  $(".text-accordion__toggler").on("click",function(e){
    let content = $(this).next(".text-accordion__content");
    let parent = $(this).parent();

    $('.text-accordion').not(parent).removeClass('active');

    if(parent.hasClass('active')){
      parent.removeClass('active');
    } else {
      parent.addClass('active');
    }

    $(".text-accordion__content").not(content).slideUp('fast');
    content.slideToggle('fast');
  });
});
