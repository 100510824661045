$(document).ready(function (e) {
  $("#contact_file, #cities_file").on("change", handleFileLoaded);
  $("#open_modal_btn").on("click", showConfirmation);
  $("#close_modal_btn").on("click", (_) => {
    resetUploadCSVForm();
  });
  $("#cities_file_btn").on("click", uploadCitiesFile);
  $("#citiesUploadVisibility").on("click", handleCitiesUploadVisibility);
});

function handleFileLoaded(e) {
  e.preventDefault();
  const input = $(this);

  if (!input.val()) return;

  const fileName = input
    .val()
    .split(/(\\|\/)/g)
    .pop();

  input.parent().siblings(".upload-file-info").text(fileName);
  input.parent().parent().parent().find("button").prop("disabled", false);
}

function showConfirmation(e) {
  function showConfirmationMessage(data) {
    const serviceUrl = $("#service").val();
    const country = $("#contact_country").val();

    setCountryOnConfirmation();
    $("#create_new_contact_btn").off();
    $("#create_new_contact_btn").on("click", function (e) {
      e.preventDefault();
      const confirmBtn = $(this);

      confirmBtn.prop("disabled", true);
      $("#close_modal_btn").prop("disabled", true);

      $.ajax({
        type: "POST",
        url: `${serviceUrl}?action=create&country=${country}`,
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: (data) => {
          confirmBtn.prop("disabled", false);
          $("#close_modal_btn").prop("disabled", false);
          $("#close_modal_btn").trigger("click");
          $.ajax({ type: "delete", url: `${serviceUrl}?country=${country}` });
        },
        error: function () {
          alert("An error happened while creating the contacts!");
        },
      });
    });

    $.ajax({
      type: "POST",
      url: `${serviceUrl}?action=analyze&country=${country}`,
      data: JSON.stringify(data),
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        $(".contacts_identified > strong").text(data.new_contacts);

        $(".contacts_stored > strong").text(data.current_contacts || 0);

        $(".date_last_upload > strong").text(
          data.current_creationTime ? formatDate(data.current_creationTime) : "-"
        );

        $("#create_new_contact_btn").prop("disabled", false);
      },
      error: function () {
        alert("An error happened while analyzing the file!");
      },
    });
  }

  $("#create_new_contact_btn").prop("disabled", true);
  parseContactCSVData("#contact_file", showConfirmationMessage);
}

function parseContactCSVData(elementSelector, callback) {
  if (!$(elementSelector).length || typeof callback !== "function") return;

  $(elementSelector).parse({
    config: {
      delimiter: ";",
      encoding: "UTF-8",
      complete: function (data) {
        let fileParsedData = groupCSVRows(data);
        callback(fileParsedData);
      },
    },
    error: function (err, file) {
      // console.error("ERROR:", err, file);
      console.error("We've encountered an error while parsing the CSV!");
    },
  });
}

function groupCSVRows(results) {
  if (!results || !results.data) return;

  const data = results.data.slice(2);

  const groupedData = {};

  data.forEach((row) => {
    const office = row[1] && row[1].trim();
    const serviceArea = row[2] && row[2].trim();
    const service = row[3] && row[3].trim();

    if (!office || !serviceArea || !service) return true;

    const contact1 = row.slice(4, 8).filter((str) => !!str);
    const contact2 = row.slice(8, 12).filter((str) => !!str);
    const contact3 = row.slice(12, 16).filter((str) => !!str);

    const rowContacts = [contact1, contact2, contact3].filter((a) => a.length);

    if (!groupedData[office]) groupedData[office] = {};
    if (!groupedData[office][serviceArea])
      groupedData[office][serviceArea] = {};
    if (!groupedData[office][serviceArea][service])
      groupedData[office][serviceArea][service] = [];

    const currentContacts = groupedData[office][serviceArea][service];

    groupedData[office][serviceArea][service] =
      currentContacts.concat(rowContacts);
  });

  return groupedData;
}

function formatDate(timestamp) {
  const date = new Date(parseInt(timestamp));

  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth()}`.slice(-2);
  const year = date.getFullYear();

  const hour = `0${date.getHours()}`.slice(-2);
  const min = `0${date.getMinutes()}`.slice(-2);

  return `${day}/${month}/${year} at ${hour}:${min}`;
}

function resetUploadCSVForm() {
  $("#contact_file").val("");
  $(".upload-file-info.contact").text("");
  $("#open_modal_btn").prop("disabled", true);
}

function setCountryOnConfirmation() {
  const countryName = $("#contact_country option:selected").text();
  $(".country_destination strong").text(countryName);
}

function uploadCitiesFile(e) {
  const btn = $(this);
  const serviceUrl = $("#serviceCities").val();
  const country = $("#contact_country").val();

  $("#cities_file").parse({
    config: {
      delimiter: ",",
      encoding: "UTF-8",
      header: true,
      complete: function (data) {
        const formattedData = data.data
          .filter((d) => d.Locality && d.Latitude && d.Longitude)
          .map((d) => {
            return {
              name: d.Locality,
              location: `${d.Latitude},${d.Longitude}`,
            };
          });

        if (!formattedData.length) {
          alert("No cities found in the file!");
          return;
        }

        btn.prop("disabled", true);

        $.ajax({
          type: "POST",
          url: `${serviceUrl}?country=${country}`,
          data: JSON.stringify(formattedData),
          dataType: "json",
          contentType: "application/json; charset=utf-8",
          success: (data) => {
            $(".upload-file-info.citites-info").text("");
            $("#cities_file").val("");
            $(".upload-file-info.citites-info").text("Success!");
          },
          error: function () {
            alert("An error happened while creating cities!");
            btn.prop("disabled", false);
          },
        });
      },
    },
    error: function (err, file) {
      console.error("We've encountered an error while parsing the CSV!");
    },
  });
}

function handleCitiesUploadVisibility(e) {
  e.preventDefault();
  $(this).parent().siblings().toggle("fast");
}
