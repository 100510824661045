$(document).ready(function () {
  // Open/Close Menu/Search
  var openMenu = $("header.main-header .header__buttons--menu"),
    openCloseMenu = $(
      ".navigation .overlay, .navigation__wrapper--buttons .btn-menu, .search__buttons .btn-menu"
    ),
    navigationWrapper = $(".navigation"),
    navigationBtnActive = $(".navigation__wrapper--buttons .btn-menu");

  var openSearch = $(".header__buttons--search"),
    openCloseSearch = $(
      ".search__buttons .btn-search, .navigation__wrapper--buttons .btn-search"
    ),
    searchWrapper = $(".search"),
    searchBtnActive = $(".search__buttons .btn-search");

  openMenu.click(function () {
    showNavigation();
  });
  openCloseMenu.click(function () {
    if (navigationWrapper.hasClass("active") == true) {
      hideNavigation();
    }
    if (navigationWrapper.hasClass("inactive") == true) {
      showNavigation();
      hideSearch();
    }
  });

  openSearch.click(function () {
    showSearch();
  });
  openCloseSearch.click(function () {
    if (searchWrapper.hasClass("active") == true) {
      hideSearch();
    }
    if (searchWrapper.hasClass("inactive") == true) {
      showSearch();
      hideNavigation();
    }
  });

  function showNavigation () {
    navigationWrapper.addClass("active");
    searchWrapper.addClass("inactive");
    navigationWrapper.fadeIn(300, "linear");
    navigationBtnActive.find(".menuCloseIcon").addClass("open");
  }

  function hideNavigation () {
    navigationWrapper.removeClass("active");
    searchWrapper.removeClass("inactive");
    navigationWrapper.fadeOut(300, "linear");
    navigationBtnActive.find(".menuCloseIcon").removeClass("open");
  }

  function showSearch () {
    searchWrapper.addClass("active");
    navigationWrapper.addClass("inactive");
    searchWrapper.fadeIn(300, "linear");
    searchBtnActive.addClass("open");
    const searchInput = $(".search__inputField input")
    if (searchInput){
      searchInput.focus()
    }
  }

  function hideSearch () {
    searchWrapper.removeClass("active");
    navigationWrapper.removeClass("inactive");
    searchWrapper.fadeOut(300, "linear");
    searchBtnActive.removeClass("open");
  }

  // Open/Close Menu/Search

  // Navigation Menu
  var dropMenu = $(".dropMenu"),
    btnClose = $(".dropMenu .btnClose");

  $(
    ".dropMenu .btnClose, .main__menu--desktop .item.dropDown, .main__menu--mobile .item.menu-hamburguer"
  ).click(function () {
    dropMenu.toggleClass("active");
    btnClose.toggleClass("active");
    navigationBtnActive.find(".menuCloseIcon").toggleClass("open");
  });

  var items_dropDown_menu = $(".dropMenu__desktop--items .item a"),
    dropMenu__subItems = $(".dropMenu__desktop--subItems .subItems"),
    thisItem;

  $(items_dropDown_menu).hover(function () {
    thisItem = $(this).attr("data-subItem");
    items_dropDown_menu.removeClass("active");
    $(this).toggleClass("active");
    dropMenu__subItems.removeClass("active");
    dropMenu__subItems.each(function (index, value) {
      var _thisItem = $(this).attr("data-subItem");
      if (_thisItem == thisItem) {
        $(
          ".dropMenu__desktop--subItems .subItems[data-subItem='" +
          _thisItem +
          "']"
        ).addClass("active");
      }
    });
  });

  var items_dropDown_menu_mobile = $(".dropMenu__mobile--items .item a"),
    subItems_dropDown_menu_mobile = $(
      "header.main-header .dropMenu__mobile--items .item.item--expandable>a"
    );
  items_dropDown_menu_mobile.click(function () {
    console.log(this, $(this).hasClass("active"));
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).next(".subItems").removeClass("active");
    } else {
      $(this).addClass("active");
      $(this).next(".subItems").addClass("active");
    }

    // $(this).toggleClass("active");
    // $(this).next(".subItems").toggleClass("active");
  });
  // Navigation Menu

  // Add link arrow
  var linkArrow = $(
    ".dropMenu__desktop--items .item a, .dropMenu__mobile--items .item a"
  );
  addLinkArrow(linkArrow);
  function addLinkArrow (el) {
    el.html(function () {
      var text = $(this).text().trim().split(" ");
      var last = text.pop();
      var lastWord = last.substring(0, last.length - 1);
      var lastChar = last.split("");
      var char = lastChar.pop();
      return (
        text.join(" ") +
        (last.length ? " " + lastWord + "<span>" + char + "</span>" : last)
      );
    });
  }
  // Add link arrow

  // Header Sticky
  function handleHeaderFixed () {
    var lastScrollTop = 0,
      fixedMenu = $("header.main-header.fixed");
    jQuery(document).scroll(function () {
      var distanceTop = jQuery(document).scrollTop();
      if (distanceTop > 200) {
        if (distanceTop < lastScrollTop) {
          // top
          fixedMenu.addClass("show");
        } else {
          // down
          fixedMenu.removeClass("show");
        }
        lastScrollTop = distanceTop;
      } else {
        fixedMenu.removeClass("show");
      }
    });
  }
  handleHeaderFixed();
  // Header Sticky

  // Search

  // Change placeholder
  var widthWindow = $(window).width();
  var searchBar = $(".search__inputField input");

  const desktopPlaceholder = searchBar.attr("placeholder");
  const mobilePlaceholder =
    desktopPlaceholder &&
    desktopPlaceholder.split(",").slice(0, 2).join(", ") + "...";

  buildPlaceholder(widthWindow, searchBar);

  $(window).resize(function () {
    widthWindow = $(window).width();
    buildPlaceholder(widthWindow, searchBar);
  });

  function buildPlaceholder (width, searchItem) {
    if (width <= 992) {
      searchItem.attr("placeholder", mobilePlaceholder);
    } else {
      searchItem.attr("placeholder", desktopPlaceholder);
    }
  }

  var headerActive = $('.header__options').find('a.active')

  $('.header__options a').not('.active').hover(function () {
    var $this = $(this)
    setTimeout(function () { $this.css('text-underline-offset', '7px') }, 200);
    headerActive.removeClass('active')
  }, function () {
    $(this).css('text-underline-offset', '6px')
    headerActive.addClass('active')
  })

});
