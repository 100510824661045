$(document).ready(function () {
  // Select all elements with the class "newsletter" or "footer__newsletter"
  const newsletters = $('.newsletter, .footer__newsletter');

  newsletters.each(function () {
      const newsletter = $(this);
      const newsletterLabel = newsletter.find('.custom-checkbox');
      const newsletterInput = newsletterLabel.find('input');
      const submitButton = newsletter.find('.btn--submit');

      // Listen for changes in the checkbox state
      newsletterInput.on('change', function () {
          // Enable/disable the submit button based on checkbox state
          if (newsletterInput.is(':checked')) {
              submitButton.removeClass('disabled');
              newsletterLabel.removeClass('error');
          } else {
              submitButton.addClass('disabled');
          }
      });

      // Prevent clicking the button if it has "btn--sent" or "btn--sending" class
      submitButton.on('click', function (e) {
          if (submitButton.hasClass('btn--sending') || submitButton.hasClass('btn--sent') || submitButton.hasClass('disabled')) {
              e.preventDefault();
          }
      });

      // Prevent clicking the disabled button and add "error" class to the submitButton parent (input wrapper)
      submitButton.on('click', function (e) {
          if (submitButton.hasClass('disabled')) {
              e.preventDefault();
              newsletterLabel.addClass('error');
          }
      });

      // ------------------------------ //
      // Handle the newsletter checkbox
      // ------------------------------ //
      const customCheckbox = $(this).find('.custom-checkbox')
      const input = customCheckbox.find('input')
      const a = customCheckbox.find('a')

      // Toggle the checkbox by clicking in the label text (or using a keyboard)
      customCheckbox.on('click keypress', function (e) {
        if (!$(e.target).is('a') && (e.type === 'click' || (e.type === 'keypress' && (e.key === ' ' || e.key === 'Enter')))) {
          input.prop("checked", !input.prop("checked"))
        }
      })

      // The link will work as expected, and the click event won't propagate to the checkbox.
      a.click(function (e) {
        e.stopPropagation()
      })

      a.keypress(function (e) {
        if (e.key === ' ' || e.key === 'Enter') {
          // Allow the link to be activated using the keyboard.
        }
      })
  });
});
