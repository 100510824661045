// Related content
// Apply slick on mobile and remove bootstrap classes
function slickControl () {
	// remove bootstrap classes
	$('.related-content__content').removeClass('row');
	$('.related-content__content').find('.related-content__item').removeClass('col-lg-4');
	$('.related-content__content').find('.related-content__item').removeClass('col-lg-3');

	if ($(window).width() < 992) {

		// if content is not a slider, slick
		$('.related-content__content:not(.slick-slider)').slick({
			slide: '.related-content__item',
			infinite: false,
			dots: false,
			arrows: false,
			slidesToShow: 2,
			slidesToScroll: 1,
            variableWidth: true,
			responsive: [
				// {
				// 	breakpoint: 768,
				// 	settings: {
				// 		slidesToShow: 2,
				// 		slidesToScroll: 1
				// 	}
				// },
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

	} else {

		$('.related-content__content').addClass('row');

		$('.related-content__content.career-artikler')
			.find('.related-content__item')
			.addClass('col-lg-3');

		$('.related-content__content:not(.career-artikler)')
			.find('.related-content__item')
			.addClass('col-lg-4');

		$('.related-content__content.slick-slider').slick('unslick');

	}
}

// initialize
$(window).resize(function () {
	slickControl();
});

$(document).ready(function () {
	slickControl();
});

// end Related content
