$(document).ready(function () {
  let inProIndex
  try{
    if(contactForm){
      inProIndex = false
    }
  } catch (err){
    inProIndex = true
  }

  if (inProIndex){
    const page = $('#project-index')
    const option = $('.dropdown__wrapper .dropdown .options li')
    const actionButton = $('.optionsBtn button')

    if (page.data('tagTitleBuild') || page.data('tagTitleService') || page.data('tagTitleGeography')) {
      goToScrollTop($('.projects__filter'))
    }

    let href
    option.on('click', function (e) {
      const filterId = $(this).attr('rel')
      const optionsParent = $(this).parent()
      const optionSection = optionsParent.find(">:first-child").attr('rel')
      // console.log(optionSection)

      switch (optionSection) {
        case 'building_type':
          page.data('build', filterId)
          // console.log(page.data('build'))
          break;

        case 'service':
          page.data('service', filterId)
          // console.log(page.data('service'))
          break;

        case 'geography':
          page.data('geography', filterId)
          // console.log(page.data('geography'))
          break;
      }

      href = `?page=1`
      if (page.data('build') !== 'building_type' && page.data('build') !== '') {
        href += `&bygg=${page.data('build')}`
      }

      if (page.data('service') !== 'service' && page.data('service') !== '') {
        href += `&tjeneste=${page.data('service')}`
      }

      if (page.data('geography') !== 'geography' && page.data('geography') !== '') {
        href += `&sted=${page.data('geography')}`
      }

      if ($('.optionsBtn').css('display') === 'none') {
        window.location.href = href
      }

      actionButton.prop("disabled", false)

    })

    actionButton.on('click', function (e) {
      window.location.href = href
    })
  }


});


function goToScrollTop (element) {
  $('html, body').animate(
    {
      scrollTop: element.offset().top
    }, 700)
}
