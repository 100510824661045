let CURRENT_AJAX_REQUEST = "";

$(document).ready(function () {
  // Data Layer to use for Google Tag Manager / Analytics
  window.dataLayer = window.dataLayer || [];

  const searchResult = $(".search__results");
  const btnSearch = $(".btnSearch");
  const searchInput = $(".search__inputField input");

  btnSearch.on("click", handleClickBtnSearch);

  searchInput.on("keyup", function (event) {
    if (event.key !== "Enter" || searchResult.is(":visible")) return;
    handleClickBtnSearch();
  });

  let searchTextTypingTimeout;
  searchInput.on("input", function () {
    if (!searchResult.is(":visible")) return;

    const input = $(this);
    CURRENT_AJAX_REQUEST && CURRENT_AJAX_REQUEST.abort();
    clearTimeout(searchTextTypingTimeout);

    searchTextTypingTimeout = setTimeout(() => {
      !input.val() && resetSearchPageStatus()
      input.val() && search();
    }, 500);

  });

  const btnCloseSearch = $(".btn-search");
  btnCloseSearch.on("click", resetSearchPageStatus);
});

function search() {
  const searchText = $(".search__inputField input").val();
  const serviceUrl = $("#service-url").val();
  const searchResultList = $(".search__results--list");
  const searchResult = $(".search__results");

  searchResult.hide("fast");

  // Data Layer to use for Google Tag Manager / Analytics
  window.dataLayer.push({
    'event' : 'search',
    'searchTerm' : searchText
  });

  CURRENT_AJAX_REQUEST = $.ajax({
    url: serviceUrl,
    data: `searchText=${searchText}`,
    success: function (data) {
      const items = data.items.map(getItemHTML);

      updateItemsFound(searchText, data.total);

      searchResultList.empty();
      searchResultList.append(items);
    },
    complete: function () {
      searchResult.show("slow");
    },
  });
}

function getItemHTML(item) {
  return `
  <a href="${item.url}" ${item.external ? 'target="_blank"' : ""}>
    <div class="item">
      <div class="item__col">${item.type}</div>
      <div class="item__col">${item.name}</div>
    </div>
  </a>
  `;
}

function updateItemsFound(searchText, total) {
  const divResultInfo = $(".search__results--info");
  let translatedMsg = divResultInfo.attr("data-message-many");

  if(total === 0) {
    translatedMsg = divResultInfo.attr("data-message-zero");
  }else if(total === 1){
    translatedMsg = divResultInfo.attr("data-message-one");
  }
  else {
    translatedMsg = translatedMsg.replace("{1}", total);
  }

  translatedMsg = translatedMsg.replace("{0}", `<span>"${searchText}"</span>`);

  divResultInfo.html(translatedMsg);
}

function handleClickBtnSearch() {
  if (!$(".search__inputField input").val()) return;

  $(".btnSearch").hide();
  search();
}

function resetSearchPageStatus() {
  $(".btnSearch").show("fast");
  $(".search__inputField input").val("");
  $(".search__results").hide("fast");
  $(".search__results--list").empty();
}
