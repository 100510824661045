// 1. Add class .parallax in the .main-header
// 2. Add class .parallax in the body

$(document).ready(function(){

  //// PARALLAX ////
  var bodyParallax = $("body.parallax");
  var headerParallax = bodyParallax.find(".main-header.parallax");

  if(bodyParallax.length){

    var header = headerParallax.find(".header"),
    subHeader = headerParallax.find(".subHeader"),
    parallaxMedia = $(".parallaxMedia"),
    parallaxMediaTitle = parallaxMedia.find(".parallaxMedia__title"),
    parallaxMediaImage = $(".parallaxMedia__media--image img"),
    parallaxMediaVideo = $(".parallaxMedia__media--video video"),
    media = $(".parallaxMedia__media");

    var distTop;
    var subHeaderTop = header.innerHeight() - subHeader.innerHeight();
    var titleTop = parallaxMediaTitle.offset().top;
    var mediaTop;

    // Set Image Width
    var image_width = parallaxMedia.find(".parallaxMedia__media").width();

    // Calculate Image Margin
    var image_margin = $(window).width() - image_width;

    // Set Bottom of Image
    var image_bottom = parallaxMedia.find(".parallaxMedia__media").offset().top + parallaxMedia.find(".parallaxMedia__media").height();

    animationFrontPage();

    $(window).scroll(function(){
      mediaTop = media.offset().top - headerParallax.innerHeight();
      animationFrontPage();
      addParallax(parallaxMedia.find(".parallaxMedia__media"));
    });

    $(window).resize(function () {
      mediaTop = media.offset().top - headerParallax.innerHeight();
      animationFrontPage();
      addParallax(parallaxMedia.find(".parallaxMedia__media"));
    });

    function animationFrontPage(){
      distTop = $(window).scrollTop();

      if(distTop > subHeaderTop){
        subHeader.addClass("hide");
        parallaxMediaTitle.addClass("opacity");
      }else{
        subHeader.removeClass("hide");
        parallaxMediaTitle.removeClass("opacity");
      }

      if(distTop > titleTop){
        parallaxMediaTitle.addClass("translate");
        parallaxMedia.addClass("full");
      }else{
        parallaxMediaTitle.removeClass("translate");
        parallaxMedia.removeClass("full");
      }

      if(distTop > mediaTop){
        headerParallax.css({
          "position" : "absolute",
          "top" : mediaTop
        })
      }else{
        headerParallax.css({
          "position" : "fixed",
          "top" : 0
        })
      }

    }

    function addParallax(mediaWrapper){

      // Set Top Trigger 175px under the top of the viewport
      var top_trigger = mediaWrapper.offset().top - 175;

      // Calculate Percentage from Top to Image and hit top before
      var percentage_from_to = ($(window).scrollTop() * 100) / top_trigger;

      // Calculate New Image Size
      var new_image_width = image_width + ((image_margin / 100) * percentage_from_to);

      // Calculate Percentage in the image
      var percentage_into_image = ($(window).scrollTop() * 100) / image_bottom;

      // Veriy simple possition offset on scroll from 50%
      var y_possition_offset = ((50 / 100) * percentage_into_image / 2.5);

      // Update Image Width
      mediaWrapper.width(new_image_width);

      // Set Image Position
      parallaxMediaImage.css("transform","translateY(-"+ y_possition_offset + "%)");
      parallaxMediaVideo.css("transform","translateY(-"+ y_possition_offset + "%)");
    }

  }



});
