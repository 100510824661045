$(document).ready(function(){

  //// Slider/Carousel ////

  var ourProjectsCarousel = $('.ourProjects');

  if(ourProjectsCarousel.length){

    ourProjectsCarousel.each(function(index, value){
      var thisSlide = $(this).find('.ourProjects__slider'),
      totalItems = thisSlide.find('.ourProjects__slider--item').length,
      randomNumber = getRandomInt(0, totalItems);

      thisSlide.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 100,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        // initialSlide: randomNumber,
        fade: true,
        cssEase: 'linear'
      });

      // Marks the current starting slide
      var startingSlide = thisSlide.slick('slickCurrentSlide'),
      startingLink = $(this).find('.ourProjects__box--links .link[data_ref='+ startingSlide +']');
      startingLink.addClass("active");

      //  Marks the current slide
      var links = $(this).find('.ourProjects__box--links'),
      link = links.find('.link');
      thisSlide.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        link.removeClass("active");
        links.find('.link[data_ref='+ nextSlide +']').addClass("active");
      });

      //  Pause current slide
      link.mouseenter(function(){
        thisSlide.slick('slickPause');
        var thisLink = $(this).attr("data_ref");
        thisSlide.slick('slickGoTo',thisLink);
      }).mouseleave(function() {
        thisSlide.slick('slickPlay');
      });

      // To go slide
      // link.click(function(){
      //   var thisLink = $(this).attr("data_ref");
      //   thisSlide.slick('slickGoTo',thisLink);
      // });

    });

  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  //// Slider/Carousel ////

});
