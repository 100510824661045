$(document).ready(function (e) {

  $(".offices-tabs--links a").click(function(){
    let target = $(this).attr("data-target");
    $(".offices-tabs--links a").removeClass("active");
    $(this).addClass('active');

    $(".offices-tabs--content .tabs").hide();
    $(`.offices-tabs--content .tabs.${target}`).show();
  });

});
