$(document).ready(function () {
  // Iterate over each select element
  $('.dropdown').each(function () {

    // Cache the number of options
    var $this = $(this),
      numberOfOptions = $(this).children('option').length;

    // Hides the select element
    $this.addClass('s-hidden');

    // Wrap the select element in a div
    $this.wrap('<div class="dropdown"></div>');

    // Insert a styled div to sit over the top of the hidden select element
    $this.after('<div class="styledSelect"></div>');

    // Cache the styled div
    var $styledSelect = $this.next('div.styledSelect');

    // Show the first select option in the styled div
    $styledSelect.text($this.children('option').eq(0).text());

    // Insert an unordered list after the styled div and also cache the list
    var $list = $('<ul />', {
      'class': 'options'
    }).insertAfter($styledSelect);

    // Insert a list item into the unordered list for each select option
    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }

    // Cache the list items
    var $listItems = $list.children('li');

    // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.styledSelect.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.options').slideUp('fast');
      });
      $(this).toggleClass('active').next('ul.options').slideToggle('fast');
    });

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.click(function (e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();

      // Get selected item val

      //alert($this.val());
    });

    // Hides the unordered list when clicking outside of it
    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.slideUp('fast');
    });


    if ($('#project-index').length) {
      const page = $('#project-index')
      const dropdownId = $styledSelect.parent().parent().parent().attr('id')
      if (dropdownId === 'dropdown_0') {
        $styledSelect.text(page.data('tagTitleBuild')).removeClass('active');
      } else if (dropdownId === 'dropdown_1') {
        $styledSelect.text(page.data('tagTitleService')).removeClass('active');
      } else {
        $styledSelect.text(page.data('tagTitleGeography')).removeClass('active');
      }

      $this.val($(this).attr('rel'));
      $list.hide();
    }

  });


  // ---------------------
  // FORM BUILDER DROPDOWN
  // ---------------------
  $('.selectpicker').each(function () {
    // Cache the number of options
    var $this = $(this),
      numberOfOptions = $(this).children('option').length;

    // Hides the select element
    $this.addClass('s-hidden');

    // Wrap the select element in a div
    $this.wrap('<div class="dropdown"></div>');

    // Insert a styled div to sit over the top of the hidden select element
    $this.after('<div class="styledSelect"></div>');

    // Cache the styled div
    var $styledSelect = $this.next('div.styledSelect');

    // Show the first select option in the styled div
    $styledSelect.text($this.children('option').eq(0).text());

    // Insert an unordered list after the styled div and also cache the list
    var $list = $('<ul />', {
      'class': 'options'
    }).insertAfter($styledSelect);

    // Insert a list item into the unordered list for each select option
    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }

    // Cache the list items
    var $listItems = $list.children('li');

    // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.styledSelect.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.options').slideUp('fast');
      });
      $(this).toggleClass('active').next('ul.options').slideToggle('fast');
    });

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.click(function (e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();

      // Get selected item val

      //alert($this.val());
    });

    // Hides the unordered list when clicking outside of it
    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.slideUp('fast');
    });

  });
});
