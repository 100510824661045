$(document).ready(function () {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (
    $(".articles__pagination").length > 0 ||
    $(".projects__pagination").length > 0
  ) {
    const page = urlParams.get("page");
    const nextButton = $(
      ".articles__pagination a.next, .projects__pagination a.next"
    );
    const maxPages =
      parseInt(nextButton.attr("href").replace(/\?page=(\d+).*/, "$1")) - 1;

    if (page < 1) {
      urlParams.set("page", 1);
    } else if (page > maxPages) {
      urlParams.set("page", maxPages);
    }

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({ page: urlParams.get("page") }, "", newUrl);
  } else {
    if (urlParams.has("page")) {
      urlParams.delete("page");
      const newUrl = urlParams.toString()
        ? `${window.location.pathname}?${urlParams.toString()}`
        : window.location.pathname;
      window.history.replaceState({}, "", newUrl);
    }
  }
});
