$(document).ready(function () {

  $('[name=contactForm]').find('[name=form_consent]:checkbox').on('click', function (e) {
    const isChecked = $(this).prop('checked')
    const submitButton = $('[name=contactForm]').find('[type="submit"]')

    if (!submitButton) return

    if (isChecked) {
      submitButton.prop('disabled', false)
    } else {
      submitButton.prop('disabled', true)
    }
  })

  //   $('#phoneNumber').mask('+ 00 00 00 00 00');
  // Add class "touched" to inputs, to apply error classes if necessary
  $('[type="submit"]').on('click', function (e) {
    $(".contact-form input , .contact-form textarea").addClass('touched');
  });

  // show thank you screen
  $('.contact-form__form').submit(async function (e) {

    // let senderIp = await $.get('https://ipapi.co/ip/', function(data) {
    //   return data
    // })

    const senderIp = ''



    $('[type="submit"]').text("Sending")
    $('[type="submit"]').append(`<span class="loader"></span>`)


    const formData = new FormData($('#contact-form')[0]) //eslint-disable-line

    // set the correct values selected on dropdown
    if ($('[type="submit"]').data('district')) {
      formData.delete('district')
      formData.append('district', $('[type="submit"]').data('district'))
    }

    if ($('[type="submit"]').data('service-area')) {
      formData.delete('serviceArea')
      formData.append('serviceArea', $('[type="submit"]').data('service-area'))
    }

    formData.append('senderIp', senderIp)

    let serviceUrl = $('[type="submit"]').data().service
    let form = $(this)

    $.ajax({
      url: serviceUrl,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        if (data.success) {
          form.hide();
          $(".contact-form__header").hide();
          $(".contact-form__tankyou").fadeIn('fast');
        } else {
          $('[type="submit"]').text("Send")
          $('[type="submit"]').remove(".loader")
        }
      }
    })




  });

  // show contact form again
  $('.contact-form__tankyou button').on('click', function () {
    $('.contact-form__form').fadeIn('fast');
    $(".contact-form__header").fadeIn('fast');
    $(".contact-form__tankyou").hide();

    $('[type="submit"]').text("Send")
    $('[type="submit"]').remove(".loader")

    $('[name=contactForm]').find('[type="submit"]').prop('disabled', false)

    $('.contact-form__form').each(function () {
      $(this).find(":input").each(function () {
        $(this).removeClass("touched");
      });
      this.reset()
    });
  });


  // save the correct values selected on dropdown
  $('.dropdown__wrapper.service-area-input .options li').on('click', function () {
    $('[type="submit"]').data('service-area', $(this).attr('rel'))
  });

  $('.dropdown__wrapper.district-input .options li').on('click', function () {
    $('[type="submit"]').data('district', $(this).attr('rel'))
  });


});
