$(document).ready(async function(){
  await addCarrerGobiCarousel()

  // Adding Gobi API after the carousel was loaded
  checkRerunGobi()
  checkRerunGobiCollection()
})

function checkRerunGobi(){
  // Grabbing the first story just as sample
  const gobiStory = document.querySelector('.gobi-stories')

  // If we don't have Gobi Stories in the page then we don't bother
  if (!gobiStory) return true

  // If the Gobi Stories already have their contents loades then it's done
  if (gobiStory.children && gobiStory.children.length) return true

  // Otherwise we wait a bit and trigger the Gobi Stories again, then check again
  setTimeout(() => {
    try{ gobi.discover({scroll:false}) }catch(e){}
    checkRerunGobi()
  }, 500)
}

function checkRerunGobiCollection(){
  // Grabbing the first story just as sample
  var gobiCollections = document.querySelectorAll('.gobi__item-collection .gobi-collection')
  var gobiCollectionsSingle = document.querySelectorAll('.gobi-container .gobi-collection')

  // If we don't have Gobi Stories in the page then we don't bother
  if ((!gobiCollections || gobiCollections.length === 0)
    && (!gobiCollectionsSingle || gobiCollectionsSingle.length === 0)) return true

  var textfulCollection = $('.gobi-collection-textful')
  var containers = $('.gobi-collection-textful').closest('.container')
  if (textfulCollection && textfulCollection.length && (!containers || !containers.length)) {
    textfulCollection.parent().parent().addClass('container')
  }

  // If the Gobi Stories already have their contents loades then it's done
  var gobiCollectionChildren = document.querySelectorAll('.gobi__item-collection .gobi-collection .shadow-root')

  // If all shadow DOMs were loaded (one for each gobiCollection)
  if (gobiCollectionChildren && (gobiCollectionChildren.length === gobiCollections.length)) {
    gobiCollections.forEach(gobiCollection => {
      // Injecting code on shadow DOM
      var gobiContainer = $(gobiCollection).closest('.gobi-carousel-container')[0]
      setTimeout(((gobiContainerClosure) => {
        return function() {
          var shadowRootTag = $(gobiContainerClosure).find('.shadow-root')[0]
          var gobiStepPrevious = $(gobiContainerClosure).find('.gobi-step-previous')[0]
          var gobiStepNext = $(gobiContainerClosure).find('.gobi-step-next')[0]
          var shadowRoot = shadowRootTag && shadowRootTag.shadowRoot
          if (shadowRoot) {
            var injectStyle = document.createElement('style')
            var styleText = '.gobi-card { flex-shrink: 0; }'
            injectStyle.type = 'text/css'

            injectStyle.appendChild(document.createTextNode(styleText))
            shadowRoot.append(injectStyle)

            var shadowCollectionDiv = Array.prototype.filter.call(shadowRoot.children, (function(node) {
              return node.tagName === 'DIV'
            }))[0]
            if (shadowCollectionDiv) {
              if (Math.abs(shadowCollectionDiv.scrollWidth - shadowCollectionDiv.offsetWidth) > 50) {
                shadowCollectionDiv.style.cssText = '-moz-box-pack: start; -webkit-box-pack: start; justify-content: start;'
                if (gobiStepPrevious && gobiStepPrevious.style) {
                  gobiStepPrevious.style.cssText = 'display: block;'
                }
                if (gobiStepNext && gobiStepNext.style) {
                  gobiStepNext.style.cssText = 'display: block;'
                }
              } else {
                shadowCollectionDiv.style.cssText = '-moz-box-pack: center; -webkit-box-pack: center; justify-content: center;'
                if (gobiStepPrevious && gobiStepPrevious.style) {
                  gobiStepPrevious.style.cssText = 'display: none;'
                }
                if (gobiStepNext && gobiStepNext.style) {
                  gobiStepNext.style.cssText = 'display: none;'
                }
              }
            }
          }
        }
      })(gobiContainer), 1000)
    })
  }

  // Otherwise we wait a bit and trigger the Gobi Stories again, then check again
  setTimeout(() => {
    try{ gobi.discover({scroll:false}) }catch(e){}
    checkRerunGobiCollection()
  }, 500)
}

async function addCarrerGobiCarousel(){
  $('.gobi__wrapper').each(function(num, elem){
    elem = $(elem);
    const wrapperParent = elem.parent()
    wrapperParent.addClass('gobi-carousel-container')

    if (elem.find('.gobi__item').length) {
      elem.slick({
        slide: '.gobi__item',
        infinite: false,
        dots: false,
        autoplay: false,
        arrows: true,
        appendArrows: wrapperParent,
        prevArrow: '<button id="gobi-previous"></button>',
        nextArrow: '<button id="gobi-next"></button>',
        adaptiveHeight: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
              arrows: true,
            }
          }
        ]
      });
    }

    if (elem.find('.gobi__item-collection').length) {
      const gobiCollection = elem.find('.gobi__item-collection .gobi-collection')[0]
      const gobiContainer = gobiCollection && $(gobiCollection).closest('.gobi-carousel-container')[0]
      if (gobiContainer) {
        $(gobiContainer).off('click', '**');
        $(gobiContainer).on('click', '.gobi-step-previous', function() {
          gobiCollection.scrollLeft -= 50;
          setTimeout(function() {
            gobiCollection.scrollLeft -= 50;
          }, 50)
        });
        $(gobiContainer).on('click', '.gobi-step-next', function() {
          gobiCollection.scrollLeft += 50;
          setTimeout(function() {
            gobiCollection.scrollLeft += 50;
          }, 50)
        });
      }
    }

    // if ($('.gobi__item-collection').length) {
    //   elem.slick({
    //     slide: '.gobi__item-collection',
    //     infinite: true,
    //     dots: false,
    //     autoplay: false,
    //     arrows: true,
    //     appendArrows: wrapperParent,
    //     prevArrow: '<button id="gobi-previous"></button>',
    //     nextArrow: '<button id="gobi-next"></button>',
    //     adaptiveHeight: false,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     responsive: [
    //       {
    //         breakpoint: 1,
    //         settings: {
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //           centerMode: false,
    //           arrows: true,
    //         }
    //       }
    //     ]
    //   });
    // }
  })
}
