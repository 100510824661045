$(document).ready(function () {
  const buttonFormId = $('#xp-formbuilder-submit')
  if (buttonFormId.length) {
    const form = buttonFormId.parent()
    const url = form.attr('action')
    const formId = form.attr('id')
    const formTitle = $(`#${formId} h2`).text()

    buttonFormId.on('click', null, function (e) {
      const formInfo = document.getElementById(formId)
      const formData = new FormData(formInfo) //eslint-disable-line
      const newFormData = new FormData() //eslint-disable-line
      const requiredFields = checkRequiredFields(formId, formData)

      for (var pair of formData.entries()) {
        const elementId = pair[0]
        const value = pair[1]
        const realName = $(`#${elementId}`).data('element-value')
        const realNameSanitized = realName

        newFormData.set(realNameSanitized, value)
      }

      if (requiredFields) {
        return
      }

      form.addClass('form-loading')
      $(`#${formId}`).submit(function (e) {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()

        $.ajax({
          url: url,
          type: 'POST',
          data: newFormData,
          processData: false,
          contentType: false,
          success: function (data) {
            form.removeClass('form-loading')
            $(`#${formId}`).empty()
            $(`#${formId}`).append(`<h2>${formTitle}</h2>`)
            $(`#${formId}`).append(data)
            goToScrollTop($(`#${formId}`))
          },
          error: function (request, status, error) {
            console.log(error)
            form.removeClass('form-loading')
          }
        })

        return false
      })
    })
  }
})

function goToScrollTop (element) {
  $('html, body').animate(
    {
      scrollTop: element.offset().top - 150
    }, 700)
}

function checkRequiredFields (formId, formData) {
  let requiredFields = false
  const fields = $(`#${formId} :input[required]:visible`)

  for (let i = 0; i < fields.length; i++) {
    const fieldId = fields[i].id
    const fieldValue = formData.get(fieldId)
    if (!fieldValue) {
      $(`#${fieldId}`).addClass('invalid')
      requiredFields = true
    } else {
      $(`#${fieldId}`).removeClass('invalid')
    }
  }

  return requiredFields
}
