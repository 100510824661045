// Breadcrumbs
$('.breadcrumbs li span').each(function () {

  // if breadcrumb item width is > 300px, truncate text and create tooltip
  if( $(this).width() > 300 ){
    $(this).addClass('truncate');
    $(this).parent().prepend("<div class='tooltip'><span>" + $(this).html() + "</span></div>");
  }

});
