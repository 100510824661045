  // Article featured image
  $('.featured-image--youtube .btn--video').click(function () {
    let figure = $(this).parent();
    let container = figure.parent();
    let iframeWrapper = container.children('.iframe-wrapper');
    let video = iframeWrapper.children('iframe');
    let videoURL = video.attr('src');

    iframeWrapper.fadeIn();
    figure.hide();
    video.attr('src' , video.attr('src') + "?rel=0&modestbranding=1&autoplay=1&mute=1")
  });
