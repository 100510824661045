$(function() {
  $(window).trigger('resize');
  $(window).resize(function () {
    $('.ingressItem p').line(3,' ');
  });
  setTimeout(function(){ $(window).trigger('resize');}, 100);

  // // $(window).resize(function () {
  //   var widthWindow = $(window).width();
  //   if (widthWindow <= 992) {
  //     builderSummary('.ingressItem p', 100);
  //   } else {
  //     builderSummary('.ingressItem p', 131);
  //   }
  // // });

  // // $(window).trigger('resize');

  // function builderSummary(element, characterLimit){
  //   $(element).each(function() {
  //     let post = $(this),
  //         text = post.text(),
  //         characterTotal = text.length,
  //         summary;
  //     if(characterTotal > characterLimit){
  //       summary = text.substring(0, characterLimit);
  //       post.text(summary + '...');
  //       post.next(".link").show();
  //     }
  //   });
  // }

});





