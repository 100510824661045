// ----------------------------- IMAGE CAROUSEL -----------------------------

function SetUpImgCarousel () {
  let imageCarouselIndex = 0;
  $('.image-carousel').each(function () {
    imageCarouselIndex++;
    $(this).attr('data-slider', imageCarouselIndex);
    $(this).addClass('image-carousel--' + imageCarouselIndex + '');

    // init slick slider
    $(this).slick({
      slide: '.image-carousel__slide',
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $('.image-carousel--' + imageCarouselIndex + ' .image-carousel__arrows .prev'),
      nextArrow: $('.image-carousel--' + imageCarouselIndex + ' .image-carousel__arrows .next'),
    });

    // Update arrows top position (figure centered)
    ArrowsTopPosition();
  });
}

function ArrowsTopPosition () {
  let imageCarouselIndex = 0;
  $('.image-carousel').each(function () {
    imageCarouselIndex++;

    // Set arrows top position based on figure current height
    let arrowsWrapper = $('.image-carousel--' + imageCarouselIndex + ' .image-carousel__arrows');
    let figureHeight = $(this).find('figure:eq(0)').outerHeight();
    arrowsWrapper.css('top', (figureHeight / 2) - arrowsWrapper.outerHeight() / 2);
  });
}

$(document).ready(function () {
  try {
    // Start all "image-carousel" on the page
    SetUpImgCarousel();

    // Update arrows top position on window resize
    $(window).resize(function () {
      ArrowsTopPosition();
    });
  } catch(e) {}
});

// ----------------------------- END IMAGE CAROUSEL -----------------------------
