$(document).ready(function () {


  if ($(".pop-up-site.new-website").length) {
    popUpWelcome()
  }

  if ($(".pop-up-site.ie-11").length) {
    popUpIE11()
  }

  function popUpWelcome () {
    if ($.cookie('firstVisit') === undefined) {
      $.cookie('firstVisit', 'value', { expires: 365, path: '/', secure: true })
      // your code for first visit
      var popUpSite = $(".pop-up-site.new-website"),
        closePopUp = popUpSite.find(".btn-close-pop-up, .btnWrapper a");
      popUpSite.fadeIn(100);
      closePopUp.click(function () {
        popUpSite.fadeOut(100);
      });
    }
  }

  function popUpIE11 () {
    // Internet Explorer 6-11

    const isIE = /* @cc_on!@ */false || !!document.documentMode
    if (isIE) {
      if ($.cookie('isIE') === undefined) {
        $.cookie('isIE', 'value', { path: '/', secure: true })
        // your code for IE11
        var popUpSiteIe11 = $(".pop-up-site.ie-11"),
          closePopUp = popUpSiteIe11.find(".btn-close-pop-up, .btnWrapper a");
        popUpSiteIe11.fadeIn(100);
        closePopUp.click(function () {
          popUpSiteIe11.fadeOut(100);
        });
      }
    }
  }
})



