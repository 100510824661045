jQuery(function () {
  let inJobListing
  try{
    if(contactForm){
      inJobListing = false
    }
  } catch (err){
    inJobListing = true
  }

  if (inJobListing){
    const selectOptions = $("ul.options").children("li");
    const urlParams = getAllParamsFromUrl();

    const urlLocation = urlParams.location && urlParams.location[0];

    if (urlLocation) {
      $(".styledSelect").text(decodeURIComponent(urlLocation));
    }

    selectOptions.on("click", function () {
      const val = $(this).attr("rel");

      if (!val) {
        const baseURI = `${window.location.origin}${window.location.pathname}`;
        window.location = baseURI;
        return;
      }

      const select = $("[name=location]");
      select.val(val);
      select.closest("form").trigger("submit");
    });
  }
});

function getAllParamsFromUrl() {
  var query = location.search.substr(1);
  var params = query.split("&");
  var result = {};
  for (var i = 0; i < params.length; i++) {
    var item = params[i].split("=");

    const key = item[0].replace(/\[|\]/g, "");
    const value = item[1];

    if (!result[key]) result[key] = [value];
    else result[key].push(value);
  }
  return result;
}
