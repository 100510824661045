$(document).ready(function(){
  $('.career-quote__slides').each(function(num, elem){
    elem = $(elem);
    const idInput = elem.find('.career-quote-id')
    const id = (idInput && idInput.val()) || ''
    const dotsIndicatorId = `#career-quote__indicator-${id}`

    elem.slick({
      slide: '.career-quote__content--item',
      infinite: true,
      dots: true,
      appendDots: dotsIndicatorId,
      dotsClass: 'indicator-wrapper',
      customPaging : function(slider, i) {
        return '';
      },
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      adaptiveHeight: false,
      slidesToShow: 1,
      slidesToScroll: 1
    });
  })
})
