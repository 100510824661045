$(document).ready(function () {

  // Alignment of featured items
  let widthWindow = $(window).width(),
      bigtItem = $(".projects__listProjects--item.medium .imageItem"),
      smallItem = $(".projects__listProjects--item.small .imageItem"),
      bigItemHeight;
  $(window).resize(function () {
      bigItemHeight = bigtItem.height();
      if (widthWindow <= 992) {
        smallItem.css("height", "auto");

      } else {
        smallItem.css("height", bigItemHeight);
      }
  })
  $(window).trigger('resize');

  // Filter
  // let toggleFilter = $(".projects__filter .filterBtn .btn, .projects__filter--options .optionsHeader__btnClose, .projects__filter .overlayOptions"),
  //     filterOptions = $(".projects__filter--options, .projects__filter .overlayOptions");

  // toggleFilter.click(function(){
  //   filterOptions.toggleClass("active");
  // });


});
